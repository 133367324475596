import { APP_TYPE } from '@/utils/constants';

const publicPathConfig = {
  login: () => '/auth/login',
};

const privatePathConfig = {
  portal: () => '/',

  /** Eco 3 */
  webBooking: (bookingType: string) => `/${APP_TYPE.BOOKING}/create/${bookingType}`,
  webBookingSearch: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BOOKING}/create/${bookingType}/search${query ? `?${query}` : ''}`,
  webBookingSearchFlightCalendar: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BOOKING}/best-price/${bookingType}/search${query ? `?${query}` : ''}`,
  webBookingInformationInput: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BOOKING}/create/${bookingType}/information-input${query ? `?${query}` : ''}`,
  webBookingList: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BOOKING}/${bookingType}/list${query ? `?${query}` : ''}`,
  webBookingInsertion: (bookingType: string) => `/${APP_TYPE.BOOKING}/${bookingType}/insert`,
  webBookingPaymentProcess: (bookingType: string) =>
    `/${APP_TYPE.BOOKING}/${bookingType}/payment-process`,
  webBookingPaymentProcessDetail: (bookingType: string, query: string) =>
    `/${APP_TYPE.BOOKING}/${bookingType}/payment-process?${query}`,
  webBookingDetail: (bookingType: string, transactionCode: string, query?: string) =>
    `/${APP_TYPE.BOOKING}/${bookingType}/detail/${transactionCode}${query ? `?${query}` : ''}`,
  webBookingUserManagement: () => `/${APP_TYPE.BOOKING}/manage/user`,
  webBookingServiceManagement: () => `/${APP_TYPE.BOOKING}/manage/web/list`,
  webBookingServiceConfiguration: () => `/${APP_TYPE.BOOKING}/manage/web/configuration`,
  webBookingAirlineTicketsServiceFeeManagement: () =>
    `/${APP_TYPE.BOOKING}/manage/service-fee/airline-tickets`,
  webBookingInsuranceServiceFeeManagement: () =>
    `/${APP_TYPE.BOOKING}/manage/service-fee/insurance`,
  webBookingAmusementParkTicketsServiceFeeManagement: () =>
    `/${APP_TYPE.BOOKING}/manage/service-fee/amusement-park-tickets`,
  webBookingPaymentReport: () => `/${APP_TYPE.BOOKING}/report/payment`,
  webBookingPartnerManagement: () => `/${APP_TYPE.BOOKING}/manage/partner`,
  // hotel
  webBookingHotelDetail: (query?: string) =>
    `/${APP_TYPE.BOOKING}/hotel/detail${query ? `?${query}` : ''}`,
  hotelTermsAndConditions: () => `/${APP_TYPE.BOOKING}/vi/hotel-terms-and-conditions`,
  // Amusement Park Ticket
  viewQRForAmusementParkTicket: (bookingType: string) =>
    `/${APP_TYPE.BOOKING}/${bookingType}/view-qr-for-amusement-park-ticket`,
  //tour
  webBookingTourDetail: (query?: string) =>  `/${APP_TYPE.BOOKING}/tour/detail${query ? `?${query}` : ''}`,

  /** Eco 1 */
  partnersList: () => `/${APP_TYPE.PARTNER}/list`,
  webPartnerUserManagement: () => `/${APP_TYPE.PARTNER}/manage/user`,
  partnersAirlineTicketsServiceFeeManagement: () =>
    `/${APP_TYPE.PARTNER}/manage/service-fee/airline-tickets`,
  partnersInsuranceServiceFeeManagement: () => `/${APP_TYPE.PARTNER}/manage/service-fee/insurance`,
  partnersAmusementParkTicketsServiceFeeManagement: () =>
    `/${APP_TYPE.PARTNER}/manage/service-fee/amusement-park-tickets`,
  partnerSignInListManagement: () => `/${APP_TYPE.PARTNER}/sign-in-list`,

  /** Báo cáo tổng hợp */
  issuedList: () => `/${APP_TYPE.REPORT}/issued/list`,
  voidList: () => `/${APP_TYPE.REPORT}/void/list`,
  topUpListReport: () => `/${APP_TYPE.REPORT}/top-up/list`,
  transactionListReport: () => `/${APP_TYPE.REPORT}/transactions/list`,
  depositListReport: () => `/${APP_TYPE.REPORT}/deposit/list`,

  /** Eco 2 */
  businessBooking: (bookingType: string) => `/${APP_TYPE.BUSINESS}/create/${bookingType}`,
  businessBookingSearch: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BUSINESS}/create/${bookingType}/search${query ? `?${query}` : ''}`,
  businessBookingSearchFlightCalendar: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BUSINESS}/best-price/${bookingType}/search${query ? `?${query}` : ''}`,
  businessBookingInformationInput: (bookingType: string, query?: string) =>
    `/${APP_TYPE.BUSINESS}/create/${bookingType}/information-input${query ? `?${query}` : ''}`,
  businessBookingList: (bookingType: string) => `/${APP_TYPE.BUSINESS}/${bookingType}/list`,
  businessBookingDetail: (bookingType: string, transactionCode: string, query?: string) =>
    `/${APP_TYPE.BUSINESS}/${bookingType}/detail/${transactionCode}${query ? `?${query}` : ''}`,
  businessBookingOperation: (bookingType: string) =>
    `/${APP_TYPE.BUSINESS}/${bookingType}/operation`,
  businessBookingPaymentProcess: (bookingType: string) =>
    `/${APP_TYPE.BUSINESS}/${bookingType}/payment-process`,
  businessBookingTicketVoid: () => `/${APP_TYPE.BUSINESS}/ticket/void`,
  businessBookingPaymentReportList: () => `/${APP_TYPE.BUSINESS}/report/payment`,
  businessBookingVoidReportList: () => `/${APP_TYPE.BUSINESS}/report/void`,
  businessHotelDetail: (query?: string) =>
    `/${APP_TYPE.BUSINESS}/hotel/detail${query ? `?${query}` : ''}`,
  businessTourDetail: (query?: string) => `/${APP_TYPE.BUSINESS}/tour/detail${query ? `?${query}` : ''}`,
};

const pathsMap = {
  ...publicPathConfig,
  ...privatePathConfig,
};

export type PathsMap = typeof pathsMap;

export const getPath = <TRoute extends keyof PathsMap>(
  route: TRoute,
  ...params: Parameters<PathsMap[TRoute]>
) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const pathCb: (...args: any[]) => string = pathsMap[route];
  return pathCb(...params);
};
