export enum INSURANCE_TYPE {
  DOM = 'DOM', // nội địa
  INT = 'INT', // quốc tế
  DEL = 'DEL', // trễ chuyến
}

export enum ITINERARY_TYPE {
  ONE_WAY = 1,
  ROUND_TRIP = 2,
}

export const InsuranceTypeOptions = [
  {
    label: 'Nội địa',
    value: INSURANCE_TYPE.DOM,
  },
  {
    label: 'Quốc tế',
    value: INSURANCE_TYPE.INT,
  },
  // TODO: Tạm thời đóng do nhà cung cấp chưa mở dịch vụ
  // {
  //   label: 'Trễ chuyến',
  //   value: INSURANCE_TYPE.DEL,
  // },
];

export enum SORT_BY {
  PRICE,
  INSURANCE_PACKAGE_NAME,
}

export enum CONTRACT_TYPE {
  ALL = 'ALL',
  EACH = 'EACH',
}

export enum INVOICE_STATUS {
  CANCELED = 'CANCELED',
  ISSUED = 'ISSUED',
  ISSUE_REQUEST = 'ISSUE_REQUEST',
  ON_REQUEST = 'ON_REQUEST',
  REQUEST = 'REQUEST',
}

export enum SUBMIT_FLIGHT_STATUS {
  PAID = 'PAID',
  PAID_HOLD = 'PAID_HOLD',
  PAID_HOLD_FAILED = 'PAID_HOLD_FAILED',
  PAID_ISSUE_FAILED = 'PAID_ISSUE_FAILED',
  WAIT_PAYMENT = 'WAIT_PAYMENT',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_CANCELED = 'PAYMENT_CANCEL',
  BOOK_PENDING = 'BOOK_PENDING',
  BOOK_SUCCESS = 'BOOK_SUCCESS',
  BOOK_FAILED = 'BOOK_FAILED',
  ISSUE_WAITING = 'ISSUE_WAITING',
  ISSUE_GRANTED = 'ISSUE_GRANTED',
  ISSUE_REQUEST = 'ISSUE_REQUEST',
  ISSUE_SUCCESS = 'ISSUE_SUCCESS',
  ISSUE_FAILED = 'ISSUE_FAILED',
  CANCELED = 'CANCELED',
  CHECKIN = 'CHECKIN',
  REFUND_REQUEST = 'REFUND_REQUEST',
  REFUNDED = 'REFUNDED',
  DELETED = 'DELETED',
}

export enum PAYMENT_OPTIONS {
  DEPOSIT = 'DEPOSIT',
  VTELMON = 'VTELMON',
  VNPAYGW = 'VNPAYGW',
}

export enum TYPE_IMPORT {
  MANUAL,
  EXCEL,
}
